import { useGetBrokeragePublicApi } from 'api/brokerage';
import Logo from 'assets/images/header/pt-logo-header.svg';
import { getSubdomain } from 'utils/helpers';
import HELMET_DEFAULT_CONFIG from 'config/executors/i18n-resources/helmet';
import { useLayoutEffect } from 'react';

export default function usePageDetails(apiCaller) {
    const brokerageDetails = useGetBrokeragePublicApi(apiCaller, getSubdomain());

    useLayoutEffect(() => {
        setTimeout(() => {
            document.title = brokerageDetails.data?.data?.name ?? HELMET_DEFAULT_CONFIG.en.title;
        }, 200);
    }, [brokerageDetails.data?.data?.name]);

    return {
        ...brokerageDetails,
        icon: brokerageDetails.data?.data?.small_logo ?? '/favicon.ico',
        logo: brokerageDetails.data?.data?.logo ?? Logo,
        title: brokerageDetails.data?.data?.name ?? HELMET_DEFAULT_CONFIG.en.title, // brokerage name
        commercialTerms: brokerageDetails.data?.data?.commercial_tnc ?? 'pdf/Commercial-T-C.pdf',
        termsOfCondition: brokerageDetails.data?.data?.condition_of_usage ?? 'pdf/terms-and-conditions.pdf',
        middleOffice: brokerageDetails.data?.data?.middle_office_file ?? 'pdf/sass-middle-office.pdf'
    };
}
